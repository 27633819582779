<template>
    <div class="container">
        <div class="upload">
            <div>
                <div class="filebox">
                    <input type="file" id="fileUpload" @change="fileChange($event)" class="filebtn" />
                    <div class="flletit">选择视频</div>
                </div>
                <div class="filetitle">{{ fileTitle }}</div>
                <label class="status">上传状态: <span>{{ statusText }}</span></label>
            </div>
            <div class="upload-type">
                <el-button slot="trigger" size="small" type="primary" @click="authUpload" :disabled="uploadDisabled">开始上传</el-button>
                <el-button style="margin-left: 10px" size="small" type="danger" @click="pauseUpload" :disabled="pauseDisabled">暂停</el-button>
                <el-button slot="trigger" size="small" type="success" :disabled="resumeDisabled" @click="resumeUpload">恢复上传</el-button>
            </div>
            <a-progress
                :text-inside="true"
                :stroke-width="15"
                :percentage="authProgress"
                :status="authProgress == 100 ? 'success' : 'text'"
            >
            </a-progress>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            vid: '',
            fileTitle: '',
            timeout: '',
            partSize: '',
            parallel: '',
            retryCount: '',
            retryDuration: '',
            region: 'cn-shanghai',
            userId: '1303984639806000',
            file: null,
            authProgress: 0,
            uploadDisabled: true,
            resumeDisabled: true,
            pauseDisabled: true,
            uploader: null,
            statusText: '',
        }
    },
    methods: {
        emitEventVod() {
        this.authProgress = 0
        this.fileTitle = ''
        this.statusText = ''
        this.pauseDisabled = true
        this.resumeDisabled = true
        this.uploadDisabled = true
        },
        fileChange(e) {
            this.file = e.target.files[0]
            if (!this.file) {
                this.$message.error('请先选择需要上传的文件!')
                return
            }
            if (this.file.type !== 'video/mp4') {
                this.$message.error('请选择.mp4文件!')
                return
            }
            this.fileTitle = this.file.name
            var userData = '{"Vod":{}}'
            if (this.uploader) {
                this.uploader.stopUpload()
                this.authProgress = 0
                this.statusText = ''
            }
            this.uploader = this.createUploader()
            console.log(userData)
            this.uploader.addFile(this.file, null, null, null, userData)
            this.uploadDisabled = false
            this.pauseDisabled = true
            this.resumeDisabled = true
        },
        authUpload() {
            if (this.uploader !== null) {
                this.uploader.startUpload()
                this.uploadDisabled = true
                this.pauseDisabled = false
            }
        },
        // 暂停上传
        pauseUpload() {
            if (this.uploader !== null) {
                this.uploader.stopUpload()
                this.resumeDisabled = false
                this.pauseDisabled = true
            }
        },
        // 恢复上传
        resumeUpload() {
            if (this.uploader !== null) {
                this.uploader.startUpload()
                this.resumeDisabled = true
                this.pauseDisabled = false
            }
        },
        createUploader(type) {
            let self = this
            let uploader = new AliyunUpload.Vod({
                timeout: self.timeout || 60000,
                partSize: self.partSize || 1048576,
                parallel: self.parallel || 5,
                retryCount: self.retryCount || 3,
                retryDuration: self.retryDuration || 2,
                region: self.region,
                userId: self.userId,
                // 添加文件成功
                addFileSuccess: function (uploadInfo) {
                    self.uploadDisabled = false
                    self.resumeDisabled = false
                    self.statusText = '添加文件成功, 等待上传...'
                    console.log('addFileSuccess: ' + uploadInfo.file.name)
                },
                onUploadstarted: function (uploadInfo) {
                    if (!uploadInfo.videoId) {
                        let title = uploadInfo.file.name.substr(0, uploadInfo.file.name.lastIndexOf('.'))
                        self.$http.get('/oss/file/createUploadVideo', { params: { title: title, fileName: uploadInfo.file.name } }).then(({ data: res }) => {
                            if (res.code !== 0) {
                                return self.$message.error(res.msg)
                            }
                            let uploadAuth = res.data.UploadAuth
                            let uploadAddress = res.data.UploadAddress
                            let videoId = res.data.VideoId
                            self.vid = res.data.VideoId
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                        })
                        self.statusText = '文件开始上传...'
                        console.log('onUploadStarted:' +uploadInfo.file.name +', endpoint:' + uploadInfo.endpoint +', bucket:' + uploadInfo.bucket +', object:' + uploadInfo.object)
                    } else {
                        // 如果videoId有值，根据videoId刷新上传凭证
                        self.$http.get('/oss/file/refreshUploadVideo', { params: { videoId: uploadInfo.videoId } }).then(({ data: res }) => {
                            if (res.code !== 0) {
                                return self.$message.error(res.msg)
                            }
                            let uploadAuth = res.data.UploadAuth
                            let uploadAddress = res.data.UploadAddress
                            let videoId = res.data.VideoId
                            self.vid = res.data.VideoId
                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                        })
                    }
                },
                // 文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    console.log('onUploadSucceed: ' +uploadInfo.file.name +', endpoint:' +uploadInfo.endpoint +', bucket:' +uploadInfo.bucket + ', object:' + uploadInfo.object)
                    self.statusText = '文件上传成功!'
                },
                // 文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    console.log('onUploadFailed: file:' + uploadInfo.file.name + ',code:' + code + ', message:' + message)
                    self.statusText = '文件上传失败!'
                },
                // 取消文件上传
                onUploadCanceled: function (uploadInfo, code, message) {
                    console.log('Canceled file: ' + uploadInfo.file.name + ', code: ' + code + ', message:' + message)
                    self.statusText = '文件已暂停上传'
                },
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: function (uploadInfo, totalSize, progress) {
                    console.log('onUploadProgress:file:' + uploadInfo.file.name +', fileSize:' +totalSize +', percent:' + Math.ceil(progress * 100) + '%')
                    let progressPercent = Math.ceil(progress * 100)
                    self.authProgress = progressPercent
                    self.statusText = '文件上传中...'
                },
                // 上传凭证超时
                onUploadTokenExpired: function (uploadInfo) {
                // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
                    self.$http.get('/oss/file/refreshUploadVideo', { params: { videoId: uploadInfo.videoId } }).then(({ data: res }) => {
                        if (res.code !== 0) {
                            return self.$message.error(res.msg)
                        }
                        let uploadAuth = res.data.UploadAuth
                        uploader.resumeUploadWithAuth(uploadAuth)
                        console.log('upload expired and resume upload with uploadauth ' + uploadAuth)
                    })
                    self.statusText = '文件超时...'
                },
                // 全部文件上传结束
                onUploadEnd: function (uploadInfo) {
                    console.log('onUploadEnd: uploaded all the files')
                    self.statusText = '文件上传完毕'
                    self.emitEvent(self.vid)
                },
            })
            return uploader
        },
        emitEvent(vid) {
            this.$emit('my-event', vid)
        },
    },
}
</script>
<style>
    .container {
        text-align: center;
        line-height: 1;
    }
    .upload-type {
        margin: 15px 0;
    }
    .filebox {
        width: 80px;
        height: 32px;
        color: #fff;
        background-color: #17b3a3;
        border-color: #17b3a3;
        position: relative;
        border-radius: 3px;
        text-align: center;
        line-height: 32px;
        margin: 0 auto;
    }
    .filebox .filebtn {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .filebox .flletit {
        width: 100%;
        height: 100%;
    }
    .filetitle {
        margin: 10px 0;
    }
    .status span {
        color: #ff4c52;
    }
</style>

